@if index($hamburger-types, slider) {
  /*
   * Slider
   */
  .hamburger--slider {

    .hamburger-inner {
      top: $hamburger-layer-height / 2;

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.15s;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      }
    }

    &.is-closing {
      //animation-name: hiding;
      //animation-delay: 0.01s;
      //animation-duration: 1s;
      //animation-fill-mode: forwards;
      z-index: 0;
    }

    &.is-active {
      //animation-name: hiding;
      //animation-delay: 0.01s;
      //animation-duration: 1s;
      //animation-fill-mode: forwards;

      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

        transform: translate3d(0, $y-offset, 0) rotate(45deg);
        background-color: $white;

        &::before {
          transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
          background-color: $white;
        }
      }
    }
  }
}

@keyframes hiding {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    height: 30px;
  }

  100% {
    opacity: 1;
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

p {
  @include p();

  a {
    color: $dark-gray;
  }
}

.button {
  @include button;
}

.text-center {
  text-align: center;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

img, svg {
  max-width: 100%;
}

.spacing-top {
  margin-top: rem-calc(64);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(128);
  }
}

.spacing-top-expanded {
  margin-top: rem-calc(64);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(208);
  }
}

.spacing-top-expanded-projects {
  margin-top: rem-calc(64);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(186);
  }
}

.spacing-bottom {
  margin-bottom: rem-calc(64);

  @include breakpoint(xlarge) {
    margin-bottom: rem-calc(128);
  }
}

.team {
  text-align: center;
  margin-top: rem-calc(64);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(152);
  }
}

.main {
  display: block;

  header {
    position: relative;

    h1 {
      color: $primary-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  .breadcrumb {
    ul {
      padding: rem-calc(16) rem-calc(24) rem-calc(32) rem-calc(32);

      li {
        display: inline-block;
        
        a {
          @include regular;
          font-size: rem-calc(12);
          text-decoration: none;
          color: $black;
          padding: 0;

          &::after {
            content: '>';
            padding-left: rem-calc(12);
            padding-right: rem-calc(12);
          }
        }

        &:last-child a::after {
          content: '';
        }
      }
    }
  }

  .categories {
    text-align: center;
    margin-top: rem-calc(32);
    margin-bottom: rem-calc(32);

    @include breakpoint(xlarge) {
      margin-top: rem-calc(54);
      margin-bottom: rem-calc(68);
    }

    li {
      display: inline-block;
      list-style-type: none;

      a {
        color: $black;
        text-decoration: none;
        @include regular;
        font-size: rem-calc(18);
        line-height: rem-calc(26);
        padding: rem-calc(4px) rem-calc(16);
        display: block;
      }
    }
  }
}

.template-default {
  main {
    padding-bottom: rem-calc(16);

    @include breakpoint(large) {
      padding-bottom: rem-calc(68);
    }
  }
}

.template-home {
  main header {
    height: calc(100vh - 40px);
    width: 100%;
    background-size: cover;

    @include breakpoint(large) {
      height: calc(100vh - 80px);
    }
    
    a {
      display: block;
      width: rem-calc(48);
      height: rem-calc(48);
      background-image: url(../images/icon-arrow-down-red.svg);
      background-repeat: no-repeat;
      background-size: 22px 12px;
      background-position: center;
      position: absolute;
      bottom: rem-calc(16);
      left: 50%;
      transform: translateX(-50%);

      @include breakpoint(large) {
        bottom: rem-calc(32);
      }
    }

  }

  .image-column {
    position: relative;

    &::after {
      @include breakpoint(xlarge) {
        content: ' ';
        background-color: $primary-color;
        width: 64px;
        height: 16px;
        display: block;
        position: absolute;
        bottom: 0;
        right: -80px;
        z-index: 1;
      }
    }
  }

  .right {
    position: relative;

    h2 {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint(xlarge) {
        margin-left: -(48px / 344px * 100%);
      }
    }

    .content {
      margin-left: 0;
      background-color: $white;
      padding: 0;
      margin-top: rem-calc(20);

      @include breakpoint(xlarge) {
        margin-left: -(72px / 344px * 100%);
        padding: rem-calc(14) rem-calc(32) rem-calc(64) rem-calc(32);
      }
    }
  }

  .left {
    text-align: right;
    z-index: 1;

    h2 {
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint(xlarge) {
        margin-right: -(112px / 344px * 100%);
      }
    }

    .content {
      margin-right: 0;
      background-color: $white;
      padding: 0;
      margin-top: rem-calc(20);

      @include breakpoint(xlarge) {
        margin-right: -(144px / 344px * 100%);
        padding: rem-calc(14) rem-calc(32) rem-calc(64) rem-calc(32);
      }
    }
  }

  .left + .image-column::after {
    left: -80px;
    right: auto;
  }

  //.footer-text p:first-child::first-letter {
  //  float: left;
  //  font-size: rem-calc(50);
  //  line-height: rem-calc(40);
  //  padding-top: 4px;
  //  padding-right: 3px;
  //  margin-left: -4px;
  //
  //  @include breakpoint(xlarge) {
  //    font-size: rem-calc(59);
  //    line-height: rem-calc(40);
  //  }
  //}



  article {

    .services {
      padding-top: rem-calc(44);
      padding-bottom: rem-calc(44);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @include breakpoint(large) {
        padding-top: rem-calc(88);
        padding-bottom: rem-calc(88);
      }

      h2 {
        margin-top: 0;
      }

      li {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        color: $dark-gray;
        letter-spacing: 0.01rem;
        padding: rem-calc(4) 0 rem-calc(4) rem-calc(32);
        background-image: url(../images/icon-arrow-right-red.svg);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 18px auto;

        @include breakpoint(xxlarge) {
          font-size: rem-calc(22);
          line-height: rem-calc(26);
          padding: rem-calc(12) 0 rem-calc(12) rem-calc(72);
          background-size: 24px auto;
        }
      }
    }

  }
}

.template-projects, .template-contact {
  main header {
    height: rem-calc(140);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(xlarge) {
      height: rem-calc(416);
    }
  }
}

.template-project {
  h1 {
    color: $primary-color;
    padding-left: rem-calc(30);
  }

  p {
    @include p;
    color: $dark-gray;

    a {
      color: $dark-gray;
      transition: all 0.6s ease-in;

      &:hover {
        color: $primary-color;
        text-decoration: none;
        transition: all 0.5s ease-out;
      }
    }
  }

  article section {
    p:first-child::first-letter {
      float: left;
      font-size: rem-calc(50);
      line-height: rem-calc(40);
      padding-top: 4px;
      padding-right: 3px;
      margin-left: -4px;

      @include breakpoint(xlarge) {
        font-size: rem-calc(59);
        line-height: rem-calc(40);
      }
    }

    .inner-wrapper {
      padding: rem-calc(16) rem-calc(24) rem-calc(32) rem-calc(32);
    }
  }

  aside {
    background-color: $light-gray;

    .inner-wrapper {
      padding: rem-calc(16) rem-calc(24) rem-calc(32) rem-calc(24);
    }
  }

  h3 {
    border-top: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;
    @include p;
    color: $dark-gray;
    padding: rem-calc(12) 0 rem-calc(12) rem-calc(36);
    background-image: url(../images/icon-pin.svg);
    background-repeat: no-repeat;
    background-size: auto 24px;
    background-position: left center;
  }
}

.similar {
  padding: rem-calc(32) 0 rem-calc(32) 0;

  @include breakpoint(xlarge) {
    padding: rem-calc(56) 0 rem-calc(65) 0;
  }

  h2 {
    @include h1;
    color: $primary-color;
    text-align: center;
  }
}

.showcase, .team-members ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;

  @include breakpoint(xlarge) {
    padding: 0 0 rem-calc(32) 0;
  }

  img {
    width: 100%;
  }

  .showcase-caption, .member-caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($primary-color, 0.2);
    padding: rem-calc(32);
    opacity: 0;
    transition: all 0.1s ease-out;
    text-align: left;

    h2 {
      @include showcaseheading;
      color: $white;
      margin: 0;
    }

    p {
      @include p-small;
      color: $white;
      margin: 0;

      a {
        color: $white;
        text-decoration: none;
      }
    }
  }

  li {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;

    @include breakpoint(medium) {
      flex: 0 0 47%;
      max-width: 47%;
    }

    @include breakpoint(xlarge) {
      flex: 0 0 30.2816%;
      max-width: 30.2816%;
    }

    &:hover {
      .showcase-caption, .member-caption {
        opacity: 1;
        transition: all 0.5s ease-out;
      }
    }
  }

  a {
    display: block;
    margin-bottom: 5%;
    position: relative;

    @include breakpoint(large) {
      margin-bottom: 12%;
    }

    @include breakpoint(xlarge) {
      margin-bottom: 15%;
    }
  }

}

.showcase img {
  filter: gray; /* IEfteam6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1)
}

.team-members ul {
  justify-content: center;

  li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 5%;

    @include breakpoint(large) {
      flex: 0 0 calc(50% - 32px);
      max-width: 308px;
      margin: 0 16px 32px 16px;
    }

    //@include breakpoint(xlarge) {
    //  flex: 0 0 30.2816%;
    //  max-width: 30.2816%;
    //}
  }

  li .member-caption {
    display: flex;
    flex-flow: row wrap;


    h2, p {
      flex: 0 0 100%;
      max-width: 100%;
    }

    p:last-child {
      //-webkit-align-self: flex-end;
      //-ms-flex-item-align: end;
      align-self: flex-end;
    }
    //width: calc(100% - 64px);
  }
}

.template-contact {
  h3 {
    @include h3;
  }

  p {
    text-align: right;

    a {
      color: $dark-gray;
      text-decoration: none;
    }
  }
  
  article {
    position: relative;
  }

  #map {
    height: rem-calc(300);

    @include breakpoint(xlarge) {
      position: absolute;
      width: 50%;
      height: rem-calc(800);
      z-index: 0;
    }
  }

  #map + .row {

    @include breakpoint(xlarge) {
      height: rem-calc(800);
    }
  }

  .align-self-middle {
    z-index: 1;
  }

  h2 {
    @include breakpoint(xlarge) {
      margin-top: 0;
      margin-left: -5%;
    }
  }

  .wrapper {
    background-color: $white;
    margin: 0 auto;
    padding-bottom: rem-calc(32);

    @include breakpoint(xlarge) {
      padding-bottom: 0;
      width: 100%;
      margin-left: -10%;
      padding: rem-calc(32);
    }
  }

  form {
    margin: 0 auto;

    input[type=email],
    input[type=text],
    textarea {
      width: 100%;
      color: $dark-gray;
      border: 1px solid $medium-gray;
      border-radius: 0;
      @include regular;
      padding: rem-calc(4);
      margin: rem-calc(4) 0;

      @include breakpoint(xlarge) {
        padding: rem-calc(16) rem-calc(8) rem-calc(12) rem-calc(8);
      }

      &::placeholder {
        opacity: 1;
        color: $medium-gray;
      }
    }

    textarea {
      min-height: 104px;
    }

    .button {
      display: block;
      margin: rem-calc(8) auto 0 auto;

      @include breakpoint(xlarge) {
        margin: rem-calc(24) auto 0 auto;
      }
    }
  }
}


.uniform__potty {
  position: absolute;
  left: -9999px;
}



.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

footer {

  .image {
    position: relative;
    height: rem-calc(200);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(xlarge) {
      height: rem-calc(416);
    }
    
    a {
      display: block;
    }
  }

  h2 {
    @include h1;
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
}

.footer-navigation {
  background-color: $dark-gray;
  min-height: rem-calc(140);
  padding-bottom: rem-calc(16);
  display: flex;
  flex-flow: row wrap;

  @include breakpoint(xlarge) {
    min-height: rem-calc(260);
  }

  .row {
    height: 100%;
    flex: 0 0 100%;
  }
  
  ul {
    display: flex;

    li {
      list-style-type: none;

      &::after {
        color: $white;
        content: '|';
        padding: 0 rem-calc(4);
      }

      &:last-child::after {
        content: '';
      }

      a {
        color: $white;
        text-decoration: none;
        @include regular;
        font-size: rem-calc(14);
      }
    }
  }
}

/**
 * wallop.css
 *
 * @fileoverview Default styles for wallop – recommended
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

/*
  This is the top-level selector

  It should be relative positioned
  to allow the children to be positioned absolutely
  relative to this
 */
.Wallop {
  position: relative;
}

/*
  This element groups all the items, but not the buttons

  It's a recommendation but it's very likely
  you'll want to hide any overflow from the items
  Especially when doing animations such as scale
 */
.Wallop-list {
  position: relative;
  overflow: hidden;
}

/*
  This is the item element

  By default, they are all hidden and
  positioned absolute
  I recommend always having .Wallop-item--current
  in your markup by default (probably on the first element)
 */
.Wallop-item {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  span {
    position: absolute;
    bottom: rem-calc(4);
    right: rem-calc(4);
    color: $white;
    font-size: rem-calc(14);
  }
}

/*
  This is the current item element

  All we do here, is make it visible again reset
  the position to static. Could also be relative
 */
.Wallop-item--current {
  visibility: visible;
  position: relative;
}

.Wallop-buttonPrevious,
.Wallop-buttonNext {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 6.7%;
  transform: translateY(-50%);
  display: block;
  width: rem-calc(32);
  height: rem-calc(32);
  background-color: $white;

  @include breakpoint(xlarge) {
    width: rem-calc(48);
    height: rem-calc(48);
  }

  svg {
    width: rem-calc(12);
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.Wallop-buttonNext {
  left: auto;
  right: 6.7%;
}

.header {
  height: rem-calc(54);
  width: 100%;
  background-color: $white;
  position: fixed;
  z-index: 2;

  @include breakpoint(large) {
    height: rem-calc(80);
  }

  .row,
  .branding {
    height: 100%;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: rem-calc(75);

      @include breakpoint(large) {
        max-width: rem-calc(135);
      }
    }
  }
  
  .row nav {
    position: fixed;
    right: -75%;
    width: 75%;
    height: 100%;
    background-color: $dark-gray;
    transition: all 0.2s ease-in;
    overflow: scroll;

    @include breakpoint(large) {
      right: -50%;
      width: 50%;
    }

    .menu-logo {
      display: inline-block;

      @include breakpoint(xlarge) {
        margin-left: rem-calc(55);
      }
    }

    svg {
      position: absolute;
      top: -10px;
      transform: none;
      width: rem-calc(75);
      //padding-top: rem-calc(17);

      @include breakpoint(large) {
        top: 3px;
        width: rem-calc(135);
        //padding-top: rem-calc(25);
      }
    }

    ul {
      margin: rem-calc(64) 0 rem-calc(32) 0;
      padding: 0;

      @include breakpoint(xlarge) {
        margin-top: rem-calc(104);
        margin-left: rem-calc(55);
      }

      li {
        list-style-type: none;

        a {
          @include light;
          font-size: rem-calc(30);
          line-height: rem-calc(45);
          text-decoration: none;
          color: #bdbdbd;
        }
      }
    }
    
    ul.language-switch li {
      display: inline-block;

      &:last-child {
        margin-left: rem-calc(20);
      }
    }

    ul.small li {
      a {
        @include regular;
        font-size: rem-calc(14);
      }
    }
  }
}

.header-gap {
  height: rem-calc(40);

  @include breakpoint(large) {
    height: rem-calc(80);
  }
}

.menu-open .header .row nav {
  right: 0;
  transition: all 0.2s ease-in;
}

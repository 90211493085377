@font-face {
  font-family: 'latolight';
  src: url('fonts/lato_300-webfont.woff2') format('woff2'),
    url('fonts/lato_300-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latoregular';
  src: url('fonts/lato_regular-webfont.woff2') format('woff2'),
    url('fonts/lato_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@mixin light {
  font-family: 'latolight', sans-serif;
  font-weight: normal;
}

@mixin regular {
  font-family: 'latoregular', sans-serif;
  font-weight: normal;
}

@mixin h1 {
  @include light;
  font-size: rem-calc(30);
  line-height: rem-calc(38);
  letter-spacing: 0.01rem;

  @include breakpoint(xlarge) {
    font-size: rem-calc(45);
    line-height: rem-calc(54);
  }
}

@mixin showcaseheading {
  @include light;
  font-size: rem-calc(26);
  line-height: rem-calc(34);
  letter-spacing: 0.01rem;

  @include breakpoint(xlarge) {
    font-size: rem-calc(36);
    line-height: rem-calc(40);
  }
}

@mixin h2 {
  @include light;
  font-size: rem-calc(30);
  line-height: rem-calc(38);
  letter-spacing: 0.01rem;
  color: $primary-color;

  @include breakpoint(xxlarge) {
    font-size: rem-calc(45);
    line-height: rem-calc(54);
  }
}

@mixin h3 {
  @include light;
  color: $primary-color;
}

@mixin p {
  @include regular;
  font-size: rem-calc(16);
  line-height: rem-calc(24);
  letter-spacing: 0.01rem;
  color: $dark-gray;

  @include breakpoint(xxlarge) {
    font-size: rem-calc(18);
    line-height: rem-calc(26);
  }
}

@mixin p-small {
  @include regular;
  font-size: rem-calc(14);
  line-height: rem-calc(22);
  letter-spacing: 0.01rem;
  color: $dark-gray;

  @include breakpoint(xxlarge) {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
  }
}

@mixin button {
  border: none;
  background-color: $primary-color;
  @include regular;
  color: $white;
  font-size: rem-calc(14);
  line-height: rem-calc(27);
  letter-spacing: 0.01rem;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: rem-calc(10) rem-calc(16);
  border-radius: 3px;
}
